<template>
  <div class="donate-page">
    <div class="safe-area-banner">
      <div class="fs32">现场捐赠</div>
      <div class="fs16 desc">涓滴之水成海洋，颗颗爱心变希望</div>
    </div>
    <div class="contact-card space-between">
      <div class="left-wrapper space-between">
        <div class="fs22">联系方式</div>
        <div class="info-wrapper">
          <div v-for="item in infoList" :key="item.name" class="info-item">
            <span>{{ item.name }}</span>
            <span :class="['text', {'fs20': item.isPhone}]">{{ item.text }}</span>
          </div>
        </div>
        <div class="qr-wrapper">
          <img class="qr" src="~@/assets/images/weixin_qr.png" alt="">
          <div class="tip">微信扫码关注我们</div>
        </div>
      </div>
      <img class="map" src="~@/assets/images/donate_map.png" alt="">
    </div>
  </div>
</template>

<script>
  export default {
    name: "Donate",
    data() {
      return {
        infoList: [
          { name: '联系电话：', text: '0833-5591966', isPhone: true },
          { name: '电子邮件：', text: 'xingyuanfoundation@foxmail.com' },
          { name: '联系地址：', text: '四川省峨眉山市佛光南路274号大佛禅院' },
          { name: '邮政编码：', text: '614200' },
        ]
      }
    }
  }
</script>

<style scoped lang="scss">
  .donate-page {
    .safe-area-banner {
      background: url("~@/assets/images/donate_banner.jpg") center;
      .desc {
        margin-top: 30px;
        color: rgba(white, 0.8);
      }
    }
    .contact-card {
      margin-top: 50px;
      margin-bottom: 70px;
      padding: 30px;
      box-shadow: 0 4px 25px 0 rgba(#091224, 0.07);
      .left-wrapper {
        width: 472px;
        height: 370px;
        flex-direction: column;
        .info-item {
          margin-bottom: 20px;
          .text {
            color: $font-color-2;
          }
          &:last-of-type {
            margin-bottom: 0;
          }
        }
        .qr-wrapper {
          width: 133px;
          text-align: center;
          .qr {
            width: 133px;
            height: 133px;
          }
          .tip {
            color: $font-color-2;
          }
        }
      }
      .map {
        width: 713px;
        height: 370px;
      }
    }
  }
</style>
